import {
  ref,
  computed,
  onUnmounted,
} from '@vue/composition-api'

import store from '@/store'
import router from '@/router'
import useToast from '@/utils/toast'
import useStore from '@/utils/store'
import resolveValue from '@/utils/resolveValue'
import screenshotJobStoreModule from '@/store/tagby/screenshotJob'

const screenshotJob = ref({})

export function useInitialize() {
  const initialize = () => {
    onUnmounted(() => {
      if (store.hasModule(screenshotJobStoreModule.STORE_MODULE_NAME)) {
        store.unregisterModule(screenshotJobStoreModule.STORE_MODULE_NAME)
      }
    })
  }
  return {
    initialize,
  }
}

export function useDetail() {
  if (!store.hasModule(screenshotJobStoreModule.STORE_MODULE_NAME)) {
    store.registerModule(screenshotJobStoreModule.STORE_MODULE_NAME, screenshotJobStoreModule)
  }
  const hasLoadError = ref(false)
  const { dispatch } = useStore(screenshotJobStoreModule.STORE_MODULE_NAME)
  const fetchItem = () => {
    dispatch('fetchItem', {
      idx: router.currentRoute.params.idx,
    }).then(response => {
      screenshotJob.value = response.data.data
    }).catch(() => {
      hasLoadError.value = false
    }).finally(() => {
    })
  }

  return {
    hasLoadError,
    screenshotJob,
    fetchItem,
  }
}

const timelines = ref([])
export function useTimelines() {
  if (!store.hasModule(screenshotJobStoreModule.STORE_MODULE_NAME)) {
    store.registerModule(screenshotJobStoreModule.STORE_MODULE_NAME, screenshotJobStoreModule)
  }
  const { makeToast } = useToast()
  const { dispatch } = useStore(screenshotJobStoreModule.STORE_MODULE_NAME)
  const fetchTimelines = () => {
    dispatch('fetchTimelines', {
      idx: router.currentRoute.params.idx,
    }).then(response => {
      timelines.value = response.data.data
    }).catch(() => {
      makeToast('danger', '타임라인을 불러오는데 실패했습니다')
    }).finally(() => {
    })
  }
  return {
    timelines,
    fetchTimelines,
  }
}

export function useInfoCard() {
  const stateOptions = [
    { label: 'ACTIVE', value: 'ACTIVE' },
    { label: 'INACTIVE', value: 'INACTIVE' },
  ]
  return {
    stateOptions,
    screenshotJob,
  }
}

const visibleDeleteModal = ref(false)

export function useDeleteModal() {
  if (!store.hasModule(screenshotJobStoreModule.STORE_MODULE_NAME)) {
    store.registerModule(screenshotJobStoreModule.STORE_MODULE_NAME, screenshotJobStoreModule)
  }

  const turnOn = () => {
    visibleDeleteModal.value = true
  }
  const turnOff = () => {
    visibleDeleteModal.value = false
  }

  const { dispatch } = useStore(screenshotJobStoreModule.STORE_MODULE_NAME)
  const { makeToast } = useToast()
  const { fetchItem } = useDetail()
  const { fetchTimelines } = useTimelines()
  const deleteScreenshotJob = () => {
    dispatch('deleteItem', {
      idx: router.currentRoute.params.idx,
    }).then(() => {
      makeToast('primary', '삭제에 성공했습니다')
      fetchItem()
      fetchTimelines()
    }).catch(() => {
      makeToast('danger', '삭제에 실패했습니다')
    })
  }
  return {
    visibleDeleteModal,
    turnOn,
    turnOff,
    deleteScreenshotJob,
  }
}

export function useDeleteActionButton() {
  const disabled = computed(() => screenshotJob.value.state === 'INACTIVE')
  return {
    disabled,
  }
}

export function useSaveButton() {
  const isSaving = ref(false)
  const isValid = computed(() => {
    const data = screenshotJob.value ?? {}
    return (Boolean(data.state)
    && Boolean(data.interval)
    && Boolean(data.max_ranking)
    && Boolean(data.max_screenshot_ranking)
    && Boolean(data.start_at)
    && Boolean(data.end_at)
    && Boolean(data.keywords))
  })
  const { dispatch } = useStore(screenshotJobStoreModule.STORE_MODULE_NAME)
  const { makeToast } = useToast()
  const { fetchItem } = useDetail()
  const { fetchTimelines } = useTimelines()
  const { resolveUtcDateTime } = resolveValue()

  const updateItem = () => {
    isSaving.value = true
    const data = screenshotJob.value ?? {}
    const params = {
      idx: data.idx,
      state: data.state,
      interval: data.interval,
      max_ranking: data.max_ranking,
      max_screenshot_ranking: data.max_screenshot_ranking,
      start_at: resolveUtcDateTime(data.start_at),
      end_at: resolveUtcDateTime(data.end_at),
      keywords: data.keywords,
    }
    dispatch('updateItem', params).then(() => {
      fetchItem()
      fetchTimelines()
      makeToast('primary', '저장에 성공했습니다')
    }).catch(() => {
      makeToast('danger', '저장에 실패했습니다')
    }).finally(() => {
      isSaving.value = false
    })
  }

  return {
    isSaving,
    isValid,
    updateItem,
  }
}

export function others() {

}
