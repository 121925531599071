<template>
  <BCard title="상세 정보">
    <BRow>
      <BCol cols="4">
        <BFormGroup
          label="idx"
        >
          <BFormInput
            readonly
            :value="screenshotJob.idx"
          />
        </BFormGroup>
      </BCol>
      <BCol cols="4">
        <BFormGroup
          label="type"
        >
          <BFormInput
            readonly
            :value="screenshotJob.type"
          />
        </BFormGroup>
      </BCol>
      <BCol cols="4">
        <BFormGroup
          label="campaign_idx"
        >
          <BFormInput
            readonly
            :value="screenshotJob.campaign_idx"
          />
        </BFormGroup>
      </BCol>
      <BCol cols="4">
        <BFormGroup
          label="adv_idx"
        >
          <BFormInput
            readonly
            :value="screenshotJob.adv_idx"
          />
        </BFormGroup>
      </BCol>
      <BCol cols="4">
        <BFormGroup
          label="state"
        >
          <vSelect
            v-model="screenshotJob.state"
            :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
            :options="stateOptions"
            :reduce="option => option.value"
          />
        </BFormGroup>
      </BCol>
      <BCol cols="4">
        <BFormGroup
          label="interval"
        >
          <BFormInput
            v-model="screenshotJob.interval"
            type="number"
            number
          />
        </BFormGroup>
      </BCol>
      <BCol cols="4">
        <BFormGroup
          label="max_ranking"
        >
          <BFormInput
            v-model="screenshotJob.max_ranking"
            type="number"
            number
          />
        </BFormGroup>
      </BCol>
      <BCol cols="4">
        <BFormGroup
          label="max_screenshot_ranking"
        >
          <BFormInput
            v-model="screenshotJob.max_screenshot_ranking"
            type="number"
            number
          />
        </BFormGroup>
      </BCol>
      <BCol cols="4">
        <BFormGroup
          label="start_at"
        >
          <TagbyDatetimeInputV2
            v-model="screenshotJob.start_at"
            :config="{enableTime: true, dateFormat: 'Y-m-d H:i'}"
          />
        </BFormGroup>
      </BCol>
      <BCol cols="4">
        <BFormGroup
          label="end_at"
        >
          <TagbyDatetimeInputV2
            v-model="screenshotJob.end_at"
            :config="{enableTime: true, dateFormat: 'Y-m-d H:i'}"
          />
        </BFormGroup>
      </BCol>
      <BCol cols="4">
        <BFormGroup
          label="created_at"
        >
          <TagbyDatetimeInputV2
            disabled
            :value="screenshotJob.created_at"
            :config="{enableTime: true, dateFormat: 'Y-m-d H:i'}"
          />
        </BFormGroup>
      </BCol>
    </BRow>

    <BRow>
      <BCol>
        <BFormGroup
          label="keywords"
        >
          <BFormTags
            v-model="screenshotJob.keywords"
            placeholder=""
          />
        </BFormGroup>
      </BCol>
    </BRow>
  </BCard>
</template>

<script>
import { defineComponent } from '@vue/composition-api'
import {
  BCard,
  BRow,
  BCol,
  BFormGroup,
  BFormInput,
  BFormTags,
} from 'bootstrap-vue'
import vSelect from 'vue-select'
import TagbyDatetimeInputV2 from '@/components/TagbyDatetimeInputV2.vue'
import { useInfoCard } from '../viewModel'

export default defineComponent({
  components: {
    BCard,
    BRow,
    BCol,
    BFormGroup,
    BFormInput,
    BFormTags,
    vSelect,
    TagbyDatetimeInputV2,
  },
  setup() {
    return {
      ...useInfoCard(),
    }
  },
})
</script>

<style lang="scss">
  @import '@core/scss/vue/libs/vue-select.scss';
  </style>
