<template>
  <div>
    <TagbyLoadError
      :hasLoadError="hasLoadError"
      subject="screenshot-job"
      :to="{ name: 'screenshot-job-list' }"
    />
    <BRow>
      <BCol cols="9">
        <InfoCard />
        <LogCard />
      </BCol>
      <BCol cols="3">
        <ActionCard />
        <TagbyTimeline
          :timelineData="timelines"
        />
      </BCol>
    </BRow>
  </div>
</template>

<script>
import {
  defineComponent,
} from '@vue/composition-api'
import {
  BRow,
  BCol,
} from 'bootstrap-vue'
import {
  useDetail,
  useInitialize,
  useTimelines,
} from './viewModel'
import TagbyLoadError from '@/components/TagbyLoadError.vue'
import TagbyTimeline from '@/components/TagbyTimeline.vue'
import InfoCard from './components/InfoCard.vue'
import LogCard from './components/LogCard.vue'
import ActionCard from './components/ActionCard.vue'

export default defineComponent({
  components: {
    BRow,
    BCol,
    TagbyLoadError,
    TagbyTimeline,
    InfoCard,
    LogCard,
    ActionCard,
  },
  setup() {
    const { initialize } = useInitialize()
    initialize()

    const detail = useDetail()
    const { fetchItem } = detail
    fetchItem()

    const timelines = useTimelines()
    const { fetchTimelines } = timelines
    fetchTimelines()

    return {
      ...detail,
      ...timelines,
    }
  },
})
</script>
