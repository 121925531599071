<template>
  <BButton
    variant="outline-primary"
    block
    :disabled="isSaving || !isValid"
    @click="updateItem"
  >
    <BSpinner
      v-if="isSaving"
      small
    />
    저장
  </BButton>
</template>

<script>
import { defineComponent } from '@vue/composition-api'
import {
  BButton,
  BSpinner,
} from 'bootstrap-vue'
import { useSaveButton } from '../viewModel'

export default defineComponent({
  components: {
    BButton,
    BSpinner,
  },
  setup() {
    const {
      isSaving,
      isValid,
      updateItem,
    } = useSaveButton()
    return {
      isSaving,
      isValid,
      updateItem,
    }
  },
})
</script>
