<template>
  <BCard title="로그 정보">
    <BRow>
      <BCol cols="4">
        <BFormGroup
          label="posting_count"
        >
          <BFormInput
            readonly
            :value="screenshotJob.posting_count"
          />
        </BFormGroup>
      </BCol>
      <BCol cols="4">
        <BFormGroup
          label="run_count"
        >
          <BFormInput
            readonly
            :value="screenshotJob.run_count"
          />
        </BFormGroup>
      </BCol>
      <BCol cols="4">
        <BFormGroup
          label="last_run_at"
        >
          <TagbyDatetimeInputV2
            disabled
            :value="screenshotJob.last_run_at"
          />
        </BFormGroup>
      </BCol>
    </BRow>

    <BRow>
      <BCol cols="4">
        <BFormGroup
          label="captured_count"
        >
          <BFormInput
            readonly
            :value="screenshotJob.captured_count"
          />
        </BFormGroup>
      </BCol>
      <BCol cols="4">
        <BFormGroup
          label="link_to_log"
        >
          <BLink :to="{ name: 'screenshot-log-list', query: { job_idx_list: [screenshotJob.idx] } }">
            로그 테이블로 이동
          </BLink>
        </BFormGroup>
      </BCol>
      <!-- <BCol cols="4">
        <BFormGroup
          label="last_run_at"
        >
          <TagbyDatetimeInputV2
            disabled
            :value="screenshotJob.last_run_at"
          />
        </BFormGroup>
      </BCol> -->
    </BRow>
  </BCard>
</template>

<script>
import { defineComponent } from '@vue/composition-api'
import {
  BCard,
  BRow,
  BCol,
  BFormGroup,
  BFormInput,
  BFormTags,
  BLink,
} from 'bootstrap-vue'
import TagbyDatetimeInputV2 from '@/components/TagbyDatetimeInputV2.vue'
import { useInfoCard } from '../viewModel'

export default defineComponent({
  components: {
    BCard,
    BRow,
    BCol,
    BFormGroup,
    BFormInput,
    BFormTags,
    BLink,
    TagbyDatetimeInputV2,
  },
  setup() {
    return {
      ...useInfoCard(),
    }
  },
})
</script>
